import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import * as styles from "./BigFeature.styles"
import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"

import Image from "next/image"
import React, { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"

type BlockType =
  | HomepageSingleton["fields"]["features"]["featureBlocks"]["firstBigFeatureBlock"]
  | HomepageSingleton["fields"]["features"]["featureBlocks"]["secondBigFeatureBlock"]

type LandingPageFeaturesBigFeatureProps = BlockType & {
  variant: "text-image" | "image-text"
}

export const LandingPageFeaturesBigFeature: FC<
  LandingPageFeaturesBigFeatureProps
> = ({
  variant,
  title,
  description,
  mobileImage,
  desktopImage,
  showAiLabel,
}) => {
  const { width: mobileWidth, height: mobileHeight } = getSanityImageDimensions(
    mobileImage.asset._ref,
  )
  const { width: desktopWidth, height: desktopHeight } =
    getSanityImageDimensions(desktopImage.asset._ref)

  return (
    <div className={styles.parent} data-variant={variant}>
      <div className={styles.text}>
        {showAiLabel && <span className={styles.label}>AI</span>}
        <Heading variant="h4" as="h3" mb="16">
          {title}
        </Heading>
        <Text variant="large" mb="0">
          {description}
        </Text>
      </div>
      <div data-images>
        <div className={styles.imageMobile}>
          <Image
            src={urlFor(mobileImage).auto("format").width(800).toString()}
            alt="Hero main image"
            height={mobileHeight}
            width={mobileWidth}
          />
        </div>
        <div className={styles.imageDesktop}>
          <Image
            src={urlFor(desktopImage).auto("format").width(1200).toString()}
            alt="Hero main image"
            height={desktopHeight}
            width={desktopWidth}
          />
        </div>
      </div>
    </div>
  )
}
