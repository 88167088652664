import { useSlider } from "@components/ui/slider";
import { parse } from "@config/theme";
import { FC, useRef } from "react";
import { HomepageSingleton } from "__generated__/sanity";
import { LandingPageSuccessStoriesSliderControls } from "./SliderControls";
import { LandingPageSuccessStoriesItem, LandingPageSuccessStoriesItemProps } from "./SliderItem";
type LandingPageSuccessStoriesSliderProps = {
  slides: HomepageSingleton["fields"]["successStories"]["slides"];
};
export const LandingPageSuccessStoriesSlider: FC<LandingPageSuccessStoriesSliderProps> = ({
  slides
}) => {
  const trackRef = useRef<HTMLDivElement>(null);
  const {
    prev,
    next,
    canMoveLeft,
    canMoveRight,
    index,
    onTouchEnd
  } = useSlider({
    trackRef
  });
  return <>
      <div ref={trackRef} className={track} onTouchEnd={onTouchEnd}>
        {slides.map((slide, key) => <LandingPageSuccessStoriesItem {...slide} key={slide._key} position={getSlidePosition(slides.length, key)} />)}
      </div>
      <LandingPageSuccessStoriesSliderControls prev={prev} next={next} canMoveLeft={canMoveLeft} canMoveRight={canMoveRight} index={index} slideLength={slides.length} />
    </>;
};
function getSlidePosition(sliderLength, key): LandingPageSuccessStoriesItemProps["position"] {
  return key === 0 ? "first" : key === sliderLength - 1 ? "last" : "middle";
}
const track = parse({
  mb: "32"
}, "t17ohxcl");

require("./Slider.linaria.module.css");