import { parse } from "@config/theme";
export const label = parse({
  py: "4",
  px: "8",
  bg: "border",
  display: "inline-block",
  mb: "16"
}, "l1q6tsu9");
export const parent = parse({
  display: "grid",
  bg: "borderDisabled"
}, "p1iqksmb");
export const imageMobile = parse({
  display: {
    _: "flex",
    large: "none"
  }
});
export const imageDesktop = parse({
  display: {
    _: "none",
    large: "flex"
  }
});
export const text = parse({
  py: {
    _: "40"
  },
  px: {
    _: "24",
    small: "32",
    large: "40"
  }
});

require("./BigFeature.styles.linaria.module.css");