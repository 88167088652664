import { parse } from "@config/theme";
export const parent = parse({
  display: "flex"
}, "pie8hl");
export const item = parse({
  bg: "borderDisabled",
  display: "grid",
  width: "100%"
}, "i1i0q99h");
export const content = parse({
  py: {
    _: "32",
    medium: "40"
  },
  px: {
    _: "24",
    medium: "32",
    large: "40"
  }
}, "c1ua7dw1");
export const image = parse({
  position: "relative",
  width: "100%",
  height: "100%"
}, "i1rcakts");

require("./SliderItem.styles.linaria.module.css");