import React, { FC } from "react";
import { parse } from "@config/theme";
import { Heading } from "@components/ui/typograhpy/Heading";
import { Text } from "@components/ui/typograhpy/Text";
type ContentPageSectionTitleProps = {
  title: string;
  description: string;
};
export const ContentPageSectionTitle: FC<ContentPageSectionTitleProps> = ({
  title,
  description
}) => {
  return <div className={textParent}>
      <Heading variant="h2" as="h2" mb={{
      _: "24",
      medium: "32"
    }} style={{
      whiteSpace: "pre-line"
    }}>
        {title}
      </Heading>
      <Text variant="special">{description}</Text>
    </div>;
};
const textParent = parse({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "100%"
}, "t3mnpfm");

require("./SectionTitle.linaria.module.css");