import { parse } from "@config/theme";
export const buttons = parse({
  pt: {
    _: "48",
    large: "56"
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  textAlign: "center"
}, "b9enmu6");
export const chain = parse({
  display: "flex",
  alignItems: "center",
  mb: "32"
}, "cpye1xr");

require("./Buttons.styles.linaria.module.css");