import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"
import Image from "next/image"
import * as styles from "./Item.styles"
import React, { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { LandingPageEnhancementProcessDot } from "./Dot"

type LandingPageEnhancementProcessItemProps =
  HomepageSingleton["fields"]["process"]["steps"][0] & {
    isFirst: boolean
  }

export const LandingPageEnhancementProcessItem: FC<
  LandingPageEnhancementProcessItemProps
> = ({ uspTitle, uspDescription, stepImage, detailImage, isFirst }) => {
  const { width: stepWidth, height: stepHeight } = getSanityImageDimensions(
    stepImage.asset._ref,
  )
  const { width: detailWidth, height: detailHeight } = getSanityImageDimensions(
    detailImage.asset._ref,
  )

  return (
    <div className={styles.parent}>
      <div className={styles.images}>
        <div className={styles.main}>
          <Image
            src={urlFor(stepImage).auto("format").width(800).toString()}
            alt="Main step image"
            height={stepHeight}
            width={stepWidth}
          />
        </div>
        <div className={styles.detail}>
          <Image
            src={urlFor(detailImage).auto("format").width(800).toString()}
            alt="Step detail image"
            layout="intrinsic"
            height={detailHeight}
            width={detailWidth}
          />
        </div>
      </div>
      <div className={styles.text}>
        <Heading variant="h4" as="h4" mb="12">
          {uspTitle}
        </Heading>
        <Text variant="large" mb="0">
          {uspDescription}
        </Text>
      </div>
      <div
        className={styles.desktopChain}
        data-is-first={isFirst ? "" : undefined}
      >
        <LandingPageEnhancementProcessDot />
      </div>
      <div className={styles.mobileChain}>
        <LandingPageEnhancementProcessDot />
      </div>
    </div>
  )
}
