import { parseAll, Theme } from "@config/theme"
import { cn } from "@lib/cn"
import React, { AnchorHTMLAttributes, FC, HTMLAttributes } from "react"
import { ThemeSystemProps } from "theme-system"

type SpanType = Omit<HTMLAttributes<HTMLSpanElement>, "color"> & {
  as?: "span"
}
type DivType = Omit<HTMLAttributes<HTMLDivElement>, "color"> & {
  as?: "div"
}
type LinkType = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> & {
  as?: "a"
}
type ElementType = LinkType | DivType | SpanType

type SpanVariant =
  | "button-label-big"
  | "button-label-small"
  | "input-label"
  | "input-label-active"
type SpanProps = Pick<
  ThemeSystemProps<Theme>,
  "mb" | "mr" | "mt" | "ml" | "color" | "display"
> &
  ElementType & {
    variant: SpanVariant
  }

export const Span: FC<SpanProps> = ({
  color,
  as = "span",
  variant,
  className,
  ...rest
}) => {
  const Comp = as
  return (
    // @ts-ignore
    <Comp
      // Keep data-* attributes for backward compatibility with  other usages
      // TODO: remove data-* attributes when everything is migrated to tailwind
      data-variant={variant}
      className={cn(
        getBaseSpanClass(variant),
        className,
        parseAll({ color, ...rest }),
      )}
      {...rest}
    />
  )
}

const getBaseSpanClass = (variant: SpanVariant) => {
  switch (variant) {
    case "button-label-big":
      return "font-semibold text-base"
    case "button-label-small":
      return "font-semibold text-sm"
    case "input-label":
      return "font-medium text-base lg:text-sm"
    case "input-label-active":
      return "font-medium text-xs"
  }
}
