import { Container } from "@components/ui/Container"
import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import * as styles from "./Usps.styles"
import { urlFor } from "@lib/sanity"
import Image from "next/image"
import React, { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { HeadingLink } from "@components/ui/heading-link"
import Link from "next/link"

type LandingPageUspsProps = HomepageSingleton["fields"]["usps"]

export const LandingPageUsps: FC<LandingPageUspsProps> = ({ usps }) => {
  return (
    <Container size="medium" className={styles.parent}>
      <div className={styles.grid}>
        {usps.map((usp) => (
          <div className={styles.item} key={usp._key}>
            <div className={styles.imageParent}>
              <Image
                src={urlFor(usp.uspIcon).auto("format").width(150).toString()}
                alt={usp.uspTitle}
                height={120}
                width={120}
              />
            </div>
            <div className={styles.text}>
              <Heading variant="h6" as="h4" mb="0">
                {usp.uspTitle}
              </Heading>
              <Text variant="large" mb="0">
                {usp.uspDescription}
              </Text>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <Link href="/app" passHref legacyBehavior>
          <HeadingLink color="gradient" variant="internal" size="h3">
            Start enhancing
          </HeadingLink>
        </Link>
        <HeadingLink
          color="grey"
          variant="external"
          target="_blank"
          size="h3"
          href="https://viesus.com"
        >
          More about Viesus
        </HeadingLink>
      </div>
    </Container>
  )
}
