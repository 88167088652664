import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"
import { SanityImage } from "@lib/sanity/image-type"
import Image from "next/image"
import { FC } from "react"
import * as styles from "./Images.styles"

type LandingPageHeroImagesProps = {
  mainImage: SanityImage
  codeImage: SanityImage
}

export const LandingPageHeroImages: FC<LandingPageHeroImagesProps> = ({
  mainImage,
  codeImage,
}) => {
  const { width: mainWidth, height: mainHeight } = getSanityImageDimensions(
    mainImage.asset._ref,
  )
  const { width: codeWidth, height: codeHeight } = getSanityImageDimensions(
    codeImage.asset._ref,
  )
  return (
    <>
      <div className={styles.mainImageContainer}>
        <div className={styles.scrollContainer}>
          <Image
            src={urlFor(mainImage).auto("format").width(1500).toString()}
            alt="Hero main image"
            height={mainHeight}
            width={mainWidth}
          />
        </div>
      </div>
      <div className={styles.codeImageContainer}>
        <Image
          src={urlFor(codeImage).auto("format").width(1500).toString()}
          alt="Hero main image"
          height={codeHeight}
          width={codeWidth}
        />
      </div>
    </>
  )
}
