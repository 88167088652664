import { Text } from "@components/ui/typograhpy/Text";
import { parse } from "@config/theme";
import { FC } from "react";
export const LandingPageHeroStats: FC = () => {
  return <div className={stats}>
      <div className={stat}>
        <Text variant="micro" color="shade400">
          Uploads
        </Text>
        <Text variant="special">26.372</Text>
      </div>
      <div className={stat}>
        <Text variant="micro" color="shade400">
          Enhancements
        </Text>
        <Text variant="special">23.947</Text>
      </div>
    </div>;
};
const stats = parse({
  position: "absolute",
  display: "grid"
}, "s1mf2c7f");
const stat = parse({
  py: {
    _: "8",
    large: "12"
  },
  px: {
    _: "12",
    large: "16"
  },
  bg: "border"
}, "s13ehjxc");

require("./Stats.linaria.module.css");