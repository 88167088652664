import { parse } from "@config/theme";
export const container = parse({
  position: "relative"
}, "c1ls4h5n");
export const mainImageContainer = parse({
  px: {
    _: "16",
    medium: "0"
  }
}, "m1vxknef");
export const scrollContainer = parse({}, "s1ujbvam");
export const codeImageContainer = parse({
  position: "absolute"
}, "cu6srqt");

require("./Images.styles.linaria.module.css");