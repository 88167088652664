import { parse } from "@config/theme";
export const parent = "pefxx9f";
export const grid = parse({
  display: "grid",
  mb: {
    _: "64",
    large: "96"
  }
}, "gyop07r");
export const item = parse({
  display: "grid",
  alignItems: {
    _: "flex-start",
    large: "center"
  }
}, "i1ka08ns");
export const text = "tp8co3o";
export const imageParent = parse({
  position: "relative"
});
export const buttons = parse({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}, "b167dpp5");

require("./Usps.styles.linaria.module.css");