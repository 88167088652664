import { parse } from "@config/theme";
export const textParent = parse({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "100%"
}, "t9uje08");
export const grid = parse({
  display: "grid",
  mb: {
    _: "64",
    large: "96"
  }
}, "g1cd3ijt");

require("./styles.linaria.module.css");